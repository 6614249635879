<template>
  <div class="">
    <PreLoader v-if="isLoading" />
    <ExceptionWithImg v-if="exceptionMsg" :msg="exceptionMsg" />

    <EmployeeBranchForm
      v-if="!isLoading"
      :employeeBranchData="employeeBranchData"
      :submitName="$t('edit')"
      v-on:addOrUpdateEmployeeBranch="addOrUpdateEmployeeBranch"
    />
  </div>
</template>

<script>
import PreLoader from "@/components/general/PreLoader.vue";
import ExceptionWithImg from "@/components/general/ExceptionWithImg.vue";
import { STATUS } from "@/utils/constants";
import EmployeeBranchForm from "@/components/employees/employeeBranchesTab/EmployeeBranchForm.vue";
import EmployeeBranch from "@/models/employees/employeeBranches/EmployeeBranch";
import createToastMixin from "@/utils/create-toast-mixin";

export default {
  name: "EmployeeBranchEdit",
  mixins: [createToastMixin],
  props: ["employeeBranchesToken"],
  components: {
    PreLoader,
    ExceptionWithImg,
    EmployeeBranchForm,
  },
  data() {
    return {
      isLoading: true,
      exceptionMsg: null,
      exceptionImg: null,
      language: localStorage.getItem("userLanguage") || "ar",
      userAuthorizeToken:
        this.$store.getters.userData.userPersonalData.userAuthorizeToken ||
        null,
      employeeBranchData: new EmployeeBranch(),
      // employeeBranchesToken: this.$route.params.employeeBranchesToken,
    };
  },
  methods: {
    async getEmployeeBranchesDetails() {
      this.isLoading = true;
      this.employeeBranchData.employeeBranchesToken =
        this.employeeBranchesToken || this.$route.params.employeeBranchesToken;
      try {
        let response = await this.employeeBranchData.getEmployeeBranchesDetails(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.employeeBranchData.fillData(response.data.employeeBranchesData);
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
          this.employeeBranchData = null;
        }
      } catch (error) {
        this.showMsg(this.$t("errorCatch"));
        this.employeeBranchData = null;
      }
      this.isLoading = false;
    },
    async addOrUpdateEmployeeBranch() {
      this.isLoading = true;
      try {
        let response = await this.employeeBranchData.updateEmployeeBranch(
          this.language,
          this.userAuthorizeToken
        );
        if (response.data.status == STATUS.SUCCESS) {
          this.showMsg(response.data.msg, true);
          this.$router.push({ name: "EmployeeBranchesTab" }).catch(() => {});
        } else if (response.data.status == STATUS.INVALID_TOKEN) {
          this.showMsg(response.data.msg);
          this.$store.dispatch("logoutUser");
          this.$router.push("/").catch(() => {});
        } else {
          this.showMsg(response.data.msg);
        }
      } catch (e) {
        this.showMsg(this.$t("errorCatch"));
      }
      this.isLoading = false;
    },
  },
  async created() {
    this.getEmployeeBranchesDetails();
  },
};
</script>

<style scoped lang="scss"></style>
